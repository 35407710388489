<template>
  <label for="employee-language">
    {{ t(`employee.language`) }}
  </label>
  <Select
    id="employee-language"
    :options="languages"
    optionLabel="name"
    optionValue="code"
    data-testid="language"
    v-model="selectedLanguage"
    class="w-full"
    :placeholder="t('placeholder.select', { property: t('employee.language').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    pt:list:data-testid="language-list"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  languageCode: string;
}>();

const emit = defineEmits<{
  (e: "update:languageCode", value: string): void;
}>();

const { t } = useI18n();
const languages = ref<Array<{ name: string; code: string }>>([]);

const isActive = ref(false);

const selectedLanguage = computed<string>({
  get: () => {
    return props.languageCode ?? "";
  },
  set: (value) => {
    emit("update:languageCode", value);
  },
});

const fetchLanguages = () => {
  languages.value = [
    {
      name: t("employee.languages.en"),
      code: "EN",
    },
    {
      name: t("employee.languages.no"),
      code: "NO",
    },
  ];

  if (selectedLanguage.value === "") {
    selectedLanguage.value = languages.value[0].code;
  }
};
onMounted(fetchLanguages);
</script>
