<template>
  <label for="clients">{{ t("employee.clients") }}</label>
  <Select
    id="clients"
    v-model="clientsSelected"
    :options="clientOptions"
    optionLabel="name"
    optionValue="id"
    data-testid="clients"
    class="inputfield w-full mb-4 mt-1"
    :class="{ 'p-invalid': val.clients.$error }"
    :placeholder="t('placeholder.select', { property: t('employee.clients').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    pt:list:data-testid="clients-list"
  />
  <small id="clients-help" data-testid="clients-error" class="p-error" v-if="val.clients.$error">{{
    val.clients.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { Client } from "@/repositories/employee/client/model/Client";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import useValidate from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps<{ clients: string[]; clientOptions: Client[] }>();
const emit = defineEmits<{ (e: "update:clients", value: string[]): void }>();

const isActive = ref(false);

const clientsSelected = computed<string>({
  get: () => {
    return props.clients.length > 0 ? props.clients[0] : "";
  },
  set: (client) => emit("update:clients", [client]),
});

const val = useValidate({ clients: { required } }, props);
</script>
