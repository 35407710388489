<template>
  <label for="business-number">{{ t("client.business-number") }}</label>
  <InputMask
    mask="999 999 999"
    v-model="businessNumberComputed"
    :class="{ 'p-invalid': val.businessNumber.$error }"
    class="inputfield w-full"
    :placeholder="t('placeholder.select', { property: t('client.business-number').toLowerCase() })"
    :unmask="true"
    id="business-number"
  />
  <small data-testid="client-business-number-error" class="p-error" v-if="val.businessNumber.$error">{{
    val.businessNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  businessNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:businessNumber", value: string | null): void;
}>();

const { t } = useI18n();

const businessNumberComputed = computed<string>({
  get: () => {
    return props.businessNumber ?? "";
  },
  set: (value) => {
    if (value.length >= 9 || value.length === 0) {
      emit("update:businessNumber", value);
    }
  },
});

const rules = {
  businessNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
