<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="saveButtonTooltip">
          <PrimeButton
            class="c-circular-button mr-4"
            @click="emit('onCommit')"
            data-testid="btn-save"
            :disabled="!hasUpdates || isSaving"
          >
            <ProgressSpinner v-if="isSaving" class="c-spinner" />
            <i class="pi pi-check c-success-button c-circular-icon"></i>
            <span class="px-4">{{ editMode ? t("common.update") : t("common.add") }}</span>
          </PrimeButton>
        </div>
        <PrimeButton
          class="c-circular-button"
          type="button"
          :label="t('common.cancel')"
          data-testid="btn-cancel"
          @click="$emit('onCancel')"
          :disabled="isSaving"
        >
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          v-if="editMode"
          class="c-circular-button ml-4"
          @click="onConfirmDelete"
          data-testid="btn-delete"
          :disabled="isSaving"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
        <ConfirmPopup data-testid="employee-delete-confirm"></ConfirmPopup>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToEmployeeSearch')"
    @saveClicked="emit('onCommit')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { UnsavedChangesDialog } from "@cumulus/components";
import { computed } from "vue";

const { t } = useI18n();

const confirm = useConfirm();

const props = defineProps<{
  hasUpdates: boolean;
  unsavedChangesDialogVisible: boolean;
  isSaving: boolean;
  editMode: boolean;
}>();

const emit = defineEmits<{
  (e: "onCommit"): void;
  (e: "onDelete"): void;
  (e: "onCancel"): void;
  (e: "stayOnPage"): void;
  (e: "routeToEmployeeSearch"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("employee.delete.dialog"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>
<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
