import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/default-theme";

import { i18n } from "./locales/i18n";
import { createApp } from "vue";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Select from "primevue/select";
import Card from "primevue/card";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Password from "primevue/password";
import SelectButton from "primevue/selectbutton";
import Panel from "primevue/panel";
import SplitButton from "primevue/splitbutton";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import Message from "primevue/message";
import FileUpload from "primevue/fileupload";
import Dialog from "primevue/dialog";
import Image from "primevue/image";
import Tooltip from "primevue/tooltip";

import { FloatLabelDropdown } from "@cumulus/components";

import { vue3Debounce } from "vue-debounce";

const MyPreset = definePreset(Aura, { DefaultTheme });

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });

  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);

  app.component("PrimeButton", Button);
  app.component("PrimeImage", Image);
  app.component("InputText", InputText);
  app.component("InputMask", InputMask);
  app.component("Checkbox", Checkbox);
  app.component("RadioButton", RadioButton);
  app.component("Select", Select);
  app.component("Card", Card);
  app.component("PrimeTextarea", Textarea);
  app.component("Toast", Toast);
  app.component("MultiSelect", MultiSelect);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("Password", Password);
  app.component("SelectButton", SelectButton);
  app.component("Panel", Panel);
  app.component("SplitButton", SplitButton);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("Message", Message);
  app.component("FileUpload", FileUpload);
  app.component("PrimeDialog", Dialog);

  app.component("FloatLabelDropdown", FloatLabelDropdown);

  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    }),
  );
  app.directive("tooltip", Tooltip);
}
