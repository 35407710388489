<template>
  <label for="company-organization-number"> {{ t(`company.organization-number`) }} </label>

  <InputMask
    mask="999 999 999"
    id="company-organization-number"
    v-model="organizationNumberComputed"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.organizationNumber.$error }"
    :unmask="true"
    :disabled="disabled"
  />

  <small class="p-error" v-if="val.organizationNumber.$error" data-testid="company-organization-number-error">
    {{ val.organizationNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { WritableComputedRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  organizationNumber: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:organizationNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  organizationNumber: {
    required,
  },
};

const organizationNumberComputed: WritableComputedRef<string> = computed({
  get: () => {
    return props.organizationNumber ?? "";
  },
  set: (value) => {
    emit("update:organizationNumber", value);
  },
});

const val = useVuelidate(rules, props);
</script>
